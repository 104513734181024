<template>
    <b-card>
        <b-input-group>
            <b-form-checkbox
                v-model="form.isSkipVerification"
                name="isSkipVerification"
                switch
            >
                ข้ามขั้นตอนยืนยันบัญชีธนาคาร
            </b-form-checkbox>
        </b-input-group>
    </b-card>
</template>

<script>
export default {
    page: {
        title: 'ตั้งค่าเอเย่นต์ | ระบบแนะนำเพื่อน',
    },
    props: {
        agentData: {
            type: Object,
            default: () => ({
                isSkipVerification: false,
            }),
        },
    },
    data() {
        return {
            form: {},
        }
    },
    watch: {
        form: {
            handler(val) {
                this.$emit('update', val)
            },
            deep: true
        }
    },
    created() {
        this.form = {
            isSkipVerification: this.agentData.isSkipVerification
        }
    }
}
</script>